import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {
  Container,
  Section,
  Seo,
  Zoom,
} from '@components'
import { LandingPageLayout } from '../../../components/site/layout/landing-page-layout'
import { OfferForm } from '../../../components/site/offer'

const offer = 'attestation'
const imgDir = '../../../components/site/images'

const Headline = () => {
  return (
    <Section>
      <Container className="mb-8 container-lg">
        <h1 className="mt-12 mb-4 text-3xl text-center">
          Mobile App Attestation
        </h1>
        <h3 className="mt-4 mb-2 text-2xl text-center text-gray-700 lg:mt-0">
          Complete App and Device Integrity without Backend Integration
          Headaches
        </h3>
      </Container>
    </Section>
  )
}

const Pitch = () => {
  return (
    <div>
      <p className="md:mt-8">
        Both Android’s SafetyNet/Play Integrity and iOS’s DeviceCheck/App Attest
        provide capabilities to check app integrity and to give some assurance
        that your backend is interacting with the authentic app.
      </p>
      <p>
        The integration of these is complex, especially in the backend. You
        need to consider usage quotas and uptime guarantees. The app checks
        also takes time to execute. It can’t be performed on every API call
        so you must persist the trust between the attestations. These OS
        facilities alone can’t provide all the fine grain security controls you
        might need.
      </p>
      <div>
        <Zoom>
          <StaticImage
            src={`${imgDir}/attestation.png`}
            alt="Approov integrated attestation"
            placeholder="blurred"
          />
        </Zoom>
      </div>
      <p>Approov mobile app protection provides:</p>
      <ul>
        <li>Seamless and consistent protection across Android and iOS</li>
        <li>
          Advanced app attestation with highly granular, policy-driven blocking
          of any tampering in the client environment
        </li>
        <li>
          Uniform Android SafetyNet and iOS DeviceCheck integration creating a
          powerful threat management framework.
        </li>
        <li>
          Protection against man-in-the-middle attacks with dynamic TLS pinning
        </li>
        <li>Protection of third-party API keys with no change to backend APIs</li>
        <li>
          Immediate over-the-air updates and dynamic management of all
          certificates and API keys
        </li>
        <li>
          Industry-standard authenticity tokens for easy backend verification
        </li>
      </ul>
    </div>
  )
}

const Action = () => {
  return (
    <div>
      <h3 className="mt-4 mb-2 text-center lg:mt-0">
        See How Approov Builds on SafetyNet and DeviceCheck
      </h3>
      <p className="mt-4">
        Approov adds greater control and consistency across SafetyNet and
        DeviceCheck - with simple integration and ongoing operations in one easy to
        use package for both Android and iOS.
      </p>
      <OfferForm offer={offer} />
    </div>
  )
}

const LandingPage = ({ location }) => {
  return (
    <LandingPageLayout pathname={location.pathname}>

      <Headline />

      <Section>
        <Container className="mb-8 container-lg">
          <div className="flex flex-col justify-center lg:flex-row lg:content-center lg:items-center">
            <div className="flex items-center justify-center lg:w-8/12">
              <Pitch />
            </div>

            <div className="lg:pl-8 lg:pr-0 lg:w-4/12">
              <Action />
            </div>
          </div>
        </Container>
      </Section>
    </LandingPageLayout>
  )
}

export default LandingPage
